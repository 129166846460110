import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import SimpleBanner from 'components/SimpleBanner';
import Teaser from 'components/Teaser';
import TextBox from 'components/TextBox';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import { LdsPageProps } from './models';

import './LdsPageEn.scss';

const LdsPage: FC<LdsPageProps> = ({
  data: {
    ldsPage: { topBanner, body, langProps, seo, ldsDocumentTypeKey, teaser, textbox },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
    pagePathname,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);
  const docCountry = process.env.GATSBY_LDS_COUNTRY;
  const docLanguage = langProps.lang.substring(0, 2).toUpperCase();

  return (
    <Layout {...{ seo, langProps, breadcrumbs, pagePathname }}>
      {topBanner?.[0] ? <SimpleBanner {...topBanner[0]} /> : null}

      {body ? (
        <section
          className={`lds-page lds-page--${docCountry}-${docLanguage}-${ldsDocumentTypeKey}`}
        >
          <div className="lds-page__body" dangerouslySetInnerHTML={{ __html: body }} />
        </section>
      ) : null}

      {textbox?.[0] ? <TextBox {...textbox[0]} /> : null}

      {teaser?.[0] ? <Teaser {...teaser[0]} /> : null}
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    ldsPage(link: { eq: $link }) {
      seo {
        ...SEOStructureFragment
      }
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      topBanner {
        ...SimpleBannerFragment
      }
      body
      ldsDocumentTypeKey
      teaser {
        ...TeaserFragment
      }
      textbox {
        ...TextboxFragment
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default LdsPage;
